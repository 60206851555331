import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import Header from "../components/sections/header"
import About from "../components/sections/about"
import Footer from "../components/sections/footer"
import GetStarted from "../components/sections/getstarted"
import Login from "../components/sections/login"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Navigation />
      <Login />
    <Footer />
  </Layout>
)

export default IndexPage
